.login-component-wrapper {
  width: 320px;
  margin: 0 auto auto auto;

  .login-component {
    margin: 40px auto auto auto;
    border-radius: 16px;
    padding: 32px;
    background: #eee;
    height: auto;

    .login-form-submit-button {
      font-size: 2em;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .link-to-login {
    padding: 32px;
  }
}
