.main-menu {
  padding: 8px;
  background: #ffdc92;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.main-menu-button {
  flex-grow: 0;
  padding: 16px;
  margin: 16px;
  font-size: 1.5em;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  transition: background-color 0.2s ease-in;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
  }
}

@media only screen and (max-width: 400px) {
  .main-menu-button {
    margin: 8px;
  }
}

#main-wrapper {
  padding: 32px 24px 24px 24px;
}

.training-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  flex-direction: column;
  padding: 16px 0 32px 0;

  .training-clock-title {
    font-size: 3em;
    &.small-title {
      font-size: 2em;
      text-align: center;
    }
  }

  .training-clock {
    font-size: 3em;
    text-align: center;
    font-family: 'sans-serif';
  }
}

.goals-and-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2em;

  .goal-sentence {
    padding: 16px 0px;
  }
}
