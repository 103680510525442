.App {
  text-align: center;
}

.App-logo {
  height: 88px;
  margin-top: -1.2em;
  padding-left: 0.5em;
}

.App-header {
  background-color: #fff;
  height: 80px;
  padding: 40px 0 20px 0;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-title {
  font-size: 3em;
  margin-left: 40px;
}

.App-intro {
  font-size: large;
}
